<template>
  <div>
    <div class="d-flex justify-space-between mb-2">
      <h3 class="text-h5 mb-2">Разрешения</h3>
      <v-btn
          v-if="can($permissions['PERMISSION.UPSERT'])"
          color="primary"
          @click="openModal"
      >
        Добавить
      </v-btn>
    </div>
    <v-card>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              №
            </th>
            <th class="text-left">
              Key
            </th>
            <th v-if="can($permissions['PERMISSION.TOGGLE'])" style="width: 150px"></th>
          </tr>
          </thead>
          <tbody>
          <tr
              class="faq-tr"
              v-for="(item, itemIndex) in permissionsList"
              :key="itemIndex"
          >
            <td>{{ itemIndex + 1 }}</td>
            <td>{{ item.keyword }}</td>
            <td v-if="can($permissions['PERMISSION.TOGGLE'])">
              <v-row justify="center">
                <v-icon
                    @click="toggle(item.id)"
                    class="cursor-pointer">
                  {{ item.isDeleted ? 'mdi-toggle-switch-off-outline' : 'mdi-toggle-switch-outline' }}
                </v-icon>
              </v-row>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <!--  Modals  -->
        <v-dialog
            v-model="dialog"
            max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span v-if="update" class="headline">Изменить Разрешения</span>
              <span v-else class="headline">Добавить Разрешения</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Key"
                        outlined
                        v-model="permissionKeyWord"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog = false"
              >
                Закрыть
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="savePermission"
              >
                Сохранить
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      permissionsList: [],
      visibilityTypes: [],
      permissionKeyWord: '',
      dialog: false,
      update: false
    }
  },
  methods: {
    async getList() {
      this.showLoader();
      try {
        const res = await this.$http.get('/api-authPermission/getPermissions');
        this.permissionsList = res.result
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.hideLoader();
      }
    },
    async getPermission(id) {
      this.update = true;
      try {
        const res = await this.$http.get(`/api-authPermission/getPermission?permissionId=${id}`);
        if (res.success) {
          this.permission = res.result;
          this.dialog = true;
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async savePermission() {
      this.showLoader();
      try {
        const res = await this.$http.get('/api-authPermission/upsertPermission', {
          params: {
            keyword: this.permissionKeyWord
          }
        });
        if (res.success) {
          await this.getList();
          this.dialog = false;
          this.successNotification();
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      } finally {
        this.hideLoader();
      }
    },
    async toggle(id) {
      try {
        const res = await this.$http.get(`/api-authPermission/toggleActivation?permissionId=${id}`);
        if (res.success) {
          this.permissionsList = res.result;
          this.successNotification();
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    openModal() {
      this.resetForm();
      this.update = false;
      this.dialog = true;
    },
    resetForm() {
      this.permission = {
        id: 0,
        key: '',
        nameUz: '',
        nameRu: '',
        nameEn: '',
        nameCy: '',
        type: '',
        visibilityType: 0
      }
    }
  },
  created() {
    this.getList();
  }
}
</script>

<style scoped>
.faq-tr:first-child .move-icon-up {
  display: none;
}

.faq-tr:last-child .move-icon-down {
  display: none;
}
</style>